<template>
  <div>
    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h3 class="mb-2 mb-md-0">ข้อมูลธุรกรรม Affiliate</h3>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="dateSelected"
              :options="dateSelectOptions"
              :clearable="false"
              :get-option-label="option => $date(option).format('MM-YYYY')"
              @option:selected="onDateSelectedChange"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <table-server-side-v-1-widget
      ref="refTableAfTransactions"
      url="api/admin/affiliate/transaction/transaction-query"
      :columns="columns"
      :actions="[{ keyEmit: 'clickView', label: 'ดูข้อมูลผู้เข้าร่วม AF', iconName: 'EyeIcon' }]"
      :load-first="false"
      @clickView="tableClickView"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      columns: [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: 'created_at',
          formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm'),
        },
        {
          label: 'ผู้เข้าร่วม',
          field: 'phone_number',
        },
        {
          label: 'ชื่อรูปหลัก',
          field: 'topic_image_name',
        },
        {
          label: 'ชื่อรูปรอง',
          field: 'topic_image_list_data_title',
        },
        {
          label: 'ราคารูป',
          field: 'topic_image_list_price',
          formatFn: value => this.gFormatNumberToCurrency(value),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'อัตตราค่าคอมมิชชั่น (%)',
          field: 'commission_rate',
          formatFn: value => `${Number(value)}%`,
          thClass: 'text-center',
          tdClass: 'text-center text-danger',
        },
        {
          label: 'ค่าคอมมิชชั่นที่ได้รับ',
          field: 'revenue',
          formatFn: value => this.gFormatNumberToCurrency(value),
          thClass: 'text-center',
          tdClass: 'text-center text-success',
        },
      ],
      dateSelectOptions: [],
      dateSelected: this.$date().format('YYYY-MM-01'),
    }
  },
  mounted() {
    this.fetchAllDateAfTransactions()
    this.$refs.refTableAfTransactions.updateParams({ date: this.dateSelected })
    this.$refs.refTableAfTransactions.reloading()
  },
  methods: {
    async fetchAllDateAfTransactions() {
      const resp = await this.api.getV2('api/admin/affiliate/transaction/transaction-date').catch(() => null)

      if (resp && resp.code === 200 && resp?.data?.length > 0) {
        this.dateSelectOptions = [...resp.data]
      }
    },
    onDateSelectedChange(newValue) {
      this.$refs.refTableAfTransactions.updateParams({ date: newValue })
      this.$refs.refTableAfTransactions.reloading()
    },
    tableClickView(rowObj) {
      this.$router.push({ name: 'affiliate-user-management-details', params: { id: rowObj.af_id } })
    },
  },
}
</script>

<style lang="scss" scoped></style>
